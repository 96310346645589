import React from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import { images } from "../../../../../constants";
import { useTheme } from "@mui/material";

const CumulativeViewRow = ({ data }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLight = theme.palette.neutral.light;
  const difference = data?.expectedTime - data?.loggedTime;

  const parseTime = (time) => {
    if (!time) return "00:00";
    const hours = time / 3600;
    const minutes = (time % 3600) / 60;
    return `${hours.toFixed(0).padStart(2, "0")}:${minutes
      .toFixed(0)
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex-direction-row gap-4 align-center align-stretch cumulative-view-row">
      <div
        className="flex-direction-row gap-8 align-center"
        style={{ width: "300px" }}
      >
        <img
          src={data?.photo || images.profilePicture}
          width={34}
          height={34}
        />
        <div className="flex-direction-column gap-4 align-start flex-1-0-0">
          <p
            className="Grotesk-500 font-14 black-text"
            style={{ color: neutralMedium }}
          >
            {data.name + " " + data.surname}
          </p>
          <p
            className="Grotesk-400 font-14 gray-text"
            style={{ color: neutralLight }}
          >
            {data?.email}
          </p>
        </div>
      </div>
      <div className="flex-direction-row gap-48 align-center flex-1-0-0 cumulative-view-row-content">
        <p className="Grotesk-400 font-14 flex-1-0-0">{data.id}</p>
        <p className="Grotesk-400 font-14 flex-1-0-0">
          {parseTime(data.loggedTime)}
        </p>
        <p className="Grotesk-400 font-14 flex-1-0-0">
          {parseTime(data.expectedTime)}
        </p>
        <p
          className="Grotesk-400 font-14 flex-1-0-0"
          style={{
            color: difference > 0 ? "green" : "red",
          }}
        >
          {difference > 0
            ? `+${parseTime(difference)}`
            : `-${parseTime(difference)}`}
        </p>
        <p className="Grotesk-400 font-14 flex-1-0-0">
          {parseTime(data?.paidTime)}
        </p>
        <p className="Grotesk-400 font-14 flex-1-0-0">{data?.days}</p>
      </div>
    </div>
  );
};

export default CumulativeViewRow;
