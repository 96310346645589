import React from "react";
import "../styles/Calendar.scss";
import "../../../styles/global.scss";
import CalendarDaySelect from "./CalendarDaySelect";

const DateSelectorRow = ({
  week,
  setSelectedDay,
  selectedDay,
  date,
  doubleDate,
}) => {
  return (
    <div className="flex-direction-row justify-space-between align-center align-stretch row">
      {week.map((day, index) => (
        <CalendarDaySelect
          key={index}
          day={day}
          setSelectedDay={(day) => setSelectedDay(day)}
          selectedDay={selectedDay}
          doubleDate={doubleDate}
          date={date}
        />
      ))}
    </div>
  );
};

export default DateSelectorRow;
