import { useState, useEffect, useRef, useCallback } from "react";

const useInfiniteScroll = (fetchData, dependencies = []) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalElements, setTotalElements] = useState(null); // Total items available in API
  const observerRef = useRef(null);

  const loadMore = useCallback(async () => {
    if (loading || (totalElements !== null && data.length >= totalElements))
      return;

    setLoading(true);
    setError(null);

    try {
      const { items, totalElements } = await fetchData(page);
      if (items.length) {
        setData((prev) => [...prev, ...items]);
        setPage((prev) => prev + 1);
      }
      setTotalElements(totalElements);
    } catch (err) {
      setError("Error loading data");
    } finally {
      setLoading(false);
    }
  }, [fetchData, page, loading, totalElements, data.length]);

  // Reset data when dependencies change (e.g., search, date, filters)
  useEffect(() => {
    setData([]);
    setPage(0);
    setTotalElements(null);
  }, dependencies);

  // Infinite Scroll Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [loadMore]);

  return { data, loading, error, observerRef, resetData: () => setData([]) };
};

export default useInfiniteScroll;
