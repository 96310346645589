import React from "react";

import { useTheme } from "@mui/material";

import Spinner from "../../../components/Spinner/Spinner";
import OtherInfoRow from "./OtherInfoRow";

const CustomFieldsTable = ({ data, isLoading, title, missingDataText }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  return (
    <div className="flex-direction-column align-start gap-32 align-stretch width-100 animated">
      <p
        className="Grotesk-500 font-16 profile-title"
        style={{ color: neutralMedium }}
      >
        {title}
      </p>
      <Spinner isLoading={isLoading}>
        <div className="flex-direction-column align-start gap-24 align-stretch width-100">
          {data && data.length > 0
            ? data.map((item, index) => (
                <OtherInfoRow key={index} item={item} />
              ))
            : !isLoading && (
                <p
                  className="Grotesk-500 font-16"
                  style={{ color: neutralMedium }}
                >
                  {missingDataText}
                </p>
              )}
        </div>
      </Spinner>
    </div>
  );
};

export default CustomFieldsTable;
