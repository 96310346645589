import React from "react";
import "../styles/Calendar.scss";
import "../../../styles/global.scss";
import ArrowButton from "./ArrowButton";

const DateSwitcher = ({ data, onClick }) => {
  return (
    <div className="flex-direction-row align-center date-switcher">
      <ArrowButton
        type="decrement"
        number={data.number}
        onClick={(type) => onClick(type)}
      />
      <p className="Grotesk-400 font-16 capitalize flex-direction-row align-center date-switcher-title">
        {data.name}
      </p>
      <ArrowButton
        type="increment"
        number={data.number}
        onClick={(type) => onClick(type)}
      />
    </div>
  );
};

export default DateSwitcher;
