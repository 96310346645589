import { icons } from "../../constants";

export const documents = [
  {
    name: "Ugovor o radu 2024.pdf ",
    created_date: "28-06-2024",
    download_date: "03-06-2024",
    size: "20MB",
  },
  {
    name: "Isplatni Listić Maj 2024.pdf ",
    created_date: "23-06-2024",
    download_date: "01-06-2024",
    size: "20MB",
  },
  {
    name: "Odmor Jun 2024.pdf  ",
    created_date: "20-06-2024",
    download_date: "27-05-2024",
    size: "20MB",
  },
  {
    name: "Obuka 2024.pdf Obuka 2024.pdfObuka 2024.pdfObuka 2024.pdfObuka 2024.pdfObuka 2024.pdf",
    created_date: "17-05-2024",
    download_date: "27-05-2024",
    size: "20MB",
  },

  {
    name: "CV.pdf ",
    created_date: "12-05-2024",
    download_date: "27-05-2024",
    size: "20MB",
  },
  {
    name: "Cover Letter.pdf ",
    created_date: "05-05-2024",
    download_date: "27-05-2024",
    size: "20MB",
  },
];
export const team = [
  {
    name: "Milan Stanojevic Milan StanojevicMilan Stanojevic     vicMilan StanojevicvicMilan Stanojevic    ",
    position:
      "HR Konsultant HR KonsultantHR KonsultantHR Konsultant HR KonsultantHR Konsultant",
    status: "green",
    type: "nadzorni",
  },
  {
    name: "Milan Stanojevic",
    position: "HR Konsultant",
    status: "green",
    type: "nadzorni",
  },

  {
    name: "Milan Stanojevic",
    position: "HR Konsultant",
    status: "green",
    type: "vas tim",
  },
  {
    name: "Milan Stanojevic",
    position: "HR Konsultant",
    status: "yellow",
    type: "vas tim",
  },
  {
    name: "Milan Stanojevic",
    position: "HR Konsultant",
    status: "red",
    type: "vas tim",
  },
];
export const documentsButtons = [
  {
    id: 1,
    title: "Radni Odnosi",
    type: "CONTRACT",
    color: "lightest",
  },
  {
    id: 2,
    title: "Isplatni Listići",
    type: "PAYSLIP",
    color: "lighter",
  },
  {
    id: 3,
    title: "Rešenja",
    type: "DECISIONS",
    color: "light",
  },
  {
    id: 4,
    title: "Cv i karton obuke",
    type: "CV",
    color: "medium",
  },
];

export const tableHeader = [
  "Ime dokumenta",
  "Datum kreiranja",
  "Datum preuzimanja ",
  "Velicina",
];

export const tableHeaderTraining = [
  "Naziv Obuke",
  "Datum učešća",
  "Mesto održavanja",
];
export const otherInfoArray = [
  {
    term: "velicinavelicinavelicinavelicinavelicina velicinavelicinavelicina",
    value: 40,
    icon: icons.ellipse,
  },
  {
    term: "Članovi porodice",
    value: [
      {
        name: "Supruznik",
        documents: [
          "Kopija lične karte",
          "Kopija izvoda iz matične knjige venčanih",
          "Kopija radne knjižice",
        ],
      },
      { name: "Maloletno Dete", documents: ["Kopija lične karte"] },
      {
        name: "Punoletno Dete",
        documents: [
          "Kopija lične karte",
          "Kopija izvoda iz matične knjige venčanih",
        ],
      },
    ],
    icon: icons.baby,
  },
  { term: "Slava", value: "Sveti Petar / 05.06.", icon: icons.saint_day },
  {
    term: "Vozač",
    value: "B Kategorija / Stalna dozvola",
    icon: icons.car,
  },
  { term: "Alergije", value: "nema", icon: icons.important },
  { term: "Disability", value: "nema", icon: icons.disability },
];

export const trainingCardArray = [
  { name: "HR Team Lead", date: "12.04.2024.", place: "Beograd, Srbiija" },
  {
    name: "HR konsultant",
    date: "12.04.2024.",
    place: "Nis, Srbiija SrbiijaSrbiijaSrbiijaSrbiijaSrbiijaSrbiijaSrbiija",
  },
];
