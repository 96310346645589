const createCompanyMutation = `
    mutation ($input: CompanyInput!) {
        createCompany(input: $input) {
            email
            address
            id
            name
            phone
            positions {
                companyId
                description
                id
                name
            }
            shortName
            slug
            taxId
        }
    }
`;
// input: CompanyInput {
//     name: String!
//     shortName: String
//     slug: String!
//     email: String!
//     phone: String!
//     address: String!
//     taxId: String!
//     registrationNumber: String!
// }

const createEmployeeMutation = `
    mutation MyMutation($input: EmployeeInput!) {
        createEmployee(input: $input) {
            id
            address
            cardId
            companyId
            nationalCardNumber
            nationality
            passportNumber
            uniqueCitizenNumber
            user {
                email
                id
                middleName
                name
                phone
                surname
            }
        }
    }
`;

const createWebLogMutation = `
    mutation ($input: WebLogInput!) {
        createWebLog(input: $input) {
            id
        }
    }
`;
const createDocumentMutation = `
    mutation ($input: DocumentInput!) {
        createDocument(input: $input) {
            url
            id
        }
    }
`;
const confirmDocumentMutation = `
    mutation ($id: UUID!) {
        confirmDocumentUpload(id: $id)
    }
`;
// input: EmployeeInput {
//     name: String!
//     middleName: String
//     surname: String!
//     email: String!
//     phone: String
//     address: String
//     nationalId: String
//     nationalCardId: String
//     companyId: UUID!
// }

const createCustomFieldMutation = `
  mutation MyMutation($companyId: UUID, $description: String, $name: String) {
    createCustomField(
      input: {companyId: $companyId, description: $description, name: $name}
    ) {
      companyId
      description
      id
      name
    }
  }
`;
const updateLogMutation = `
    mutation ($input: UpdateLogInput!) {
        updateLog(input: $input) {
            id
        }
    }
`;

const createLogMutation = `
    mutation ($input: CreateLogInput!) {
        createLog(input: $input) {
            id
        }
    }
`;

const markDocumentDownloadedMutation = `
    mutation ($id: UUID!) {
        markDocumentDownloaded(id: $id)
    }
`;
export default {
  createEmployeeMutation,
  createCompanyMutation,
  createWebLogMutation,
  createDocumentMutation,
  confirmDocumentMutation,
  createCustomFieldMutation,
  updateLogMutation,
  createLogMutation,
  markDocumentDownloadedMutation,
};
