import React, { useEffect, useState } from "react";
import "../../styles/attendancePage.scss";
import { useTheme } from "@mui/material";
import Buttons from "./components/Buttons";
import FilterPanel from "./components/FilterPanel";
import WorkHoursTracker from "./components/WorkHoursTracker";
import EmployeePerson from "./components/EmployeePerson";
import CumulativeView from "./components/CumulativeView";

const AttendanceContent = (props) => {
  const currentDate = new Date();
  let day = String(currentDate.getDate());
  let month = String(currentDate.getMonth() + 1);
  let year = String(currentDate.getFullYear());

  const [date, setDate] = useState({
    firstDate: `${year}-${String(month).padStart(2, "0")}-${String(
      day
    ).padStart(2, "0")}`,
    secondDate: "",
  });
  const [search, setSearch] = useState("");
  const [enterSearch, setEnterSearch] = useState("");
  const [option, setOption] = useState(null);
  const activeButton = props.activeContentButton;

  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;

  const changeClickedButton = (buttonId) => {
    props.receiveButtonId(buttonId);
  };
  return (
    <div
      className="flex-direction-column align-center gap-24 align-stretch shadows-2-2-8 attendance-content"
      style={{ backgroundColor: backgroundDefault }}
    >
      <Buttons
        receiveButtonId={(buttonId) => changeClickedButton(buttonId)}
        activeButton={activeButton}
      />
      <FilterPanel
        setDate={(firstDate, secondDate) => setDate({ firstDate, secondDate })}
        date={date}
        search={search}
        setSearch={setSearch}
        setEnterSearch={setEnterSearch}
        searchList={activeButton === "employee" ? true : false}
        setOption={setOption}
        doubleDates={
          activeButton === "cumulative" || activeButton === "employee"
            ? true
            : false
        }
      />
      {activeButton === "day" && (
        <WorkHoursTracker
          status={props.status}
          date={date?.firstDate}
          search={search}
          activeButton={activeButton}
          enterSearch={enterSearch}
        />
      )}
      {activeButton === "employee" &&
        option &&
        date?.firstDate &&
        date?.secondDate && (
          <EmployeePerson
            employee={option}
            from={date?.firstDate}
            to={date?.secondDate}
          />
        )}
      {activeButton === "cumulative" && date?.firstDate && date?.secondDate && (
        <CumulativeView
          search={search}
          from={date?.firstDate}
          to={date?.secondDate}
        />
      )}
    </div>
  );
};

export default AttendanceContent;
