import React, { useState } from "react";
import { useSelector } from "react-redux";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import { useTheme } from "@mui/material";
import ModalApp from "../../../components/ModalApp/ModalApp";
import {
  useCreateCustomFieldMutation,
  useGetCustomFieldsQuery,
} from "../../../store/apiSlice";
import { idSelector } from "../../../store/companySlice";
import { CustomFieldTypes } from "../../../constants/types";
import CustomFieldsTable from "./CustomFieldsTable";

const OtherInfo = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  const [customFieldType, setCustomFieldType] = useState(null);
  const [description, setDescription] = useState("");

  const companyId = useSelector(idSelector);

  const {
    data: { data = {} } = {},
    isLoading,
    isFetching,
  } = useGetCustomFieldsQuery({
    companyId,
  });

  const [createCustomField] = useCreateCustomFieldMutation({});

  const customFields = data?.customFields || [];

  const handleAddInfo = () => {
    setOpenModal(true);
  };

  const handleClearForm = () => {
    setCustomFieldType(null);
    setDescription("");
  };

  const handleSubmit = async () => {
    setOpenModal(false);
    await createCustomField({
      name: customFieldType,
      description,
      companyId,
    });
    handleClearForm();
  };

  const handleClose = () => {
    setOpenModal(false);
    handleClearForm();
  };

  return (
    <div className="flex-direction-column align-start gap-32 align-stretch width-100 animated">
      <div className="flex-direction-row align-center  width-100 other-info-btn superiors-btn">
        <ButtonApp title="Dodaj" purple onButtonClick={handleAddInfo} />
      </div>
      <CustomFieldsTable
        title="Ostale Informacije"
        data={customFields}
        isLoading={isLoading || isFetching}
        missingDataText="Nema podataka"
      />
      {openModal && (
        <div className="flex-direction-row align-center justify-center add-notification">
          <ModalApp
            title="Dodaj podatke"
            handleCloseClick={handleClose}
            handleSaveClick={handleSubmit}
            profileInfo
            documentType={customFieldType}
            optionsArray={CustomFieldTypes}
            description={description}
            setDescription={setDescription}
            onOptionsChange={setCustomFieldType}
          />
        </div>
      )}
    </div>
  );
};

export default OtherInfo;
