import React, { useState, useRef, useEffect } from "react";
import { icons } from "../../../constants";
import FamilyMemberRow from "./FamilyMemberRow";
import { useTheme } from "@mui/material";
import { CustomFieldIcons } from "../../../constants/types";

const OtherInfoRow = (props) => {
  const [showInvisible, setShowInvisible] = useState(false);
  const contentRef = useRef(null);

  const theme = useTheme();
  const backgroundAlt = theme.palette.background.alt;
  const neutralMedium = theme.palette.neutral.medium;

  return (
    <div
      className="flex-direction-column gap-12 shadows-0-0-8 width-100 other-info-row"
      style={{ backgroundColor: backgroundAlt }}
    >
      <div className="flex-direction-row align-center gap-24 width-100 other-info-row-visible">
        <div
          style={{ width: "30px" }}
          className="flex-direction-row justify-center"
        >
          <img
            src={CustomFieldIcons[props.item.name]}
            alt="icon"
            width={20}
            height={21}
          />
        </div>
        <div className="flex-direction-row justify-space-between align-center width-100">
          <p
            className="Grotesk-500 font-16 capitalize"
            style={{ color: neutralMedium }}
          >
            {props.item.name}
          </p>
          <img
            src={icons.arrow_down}
            alt="icon"
            className={`pointer other-info-arrow ${
              showInvisible ? "arrow-hidden" : "arrow-visible"
            }`}
            onClick={() => setShowInvisible(!showInvisible)}
            width={16}
            height={7}
          />
        </div>
      </div>
      <div
        ref={contentRef}
        className={`transition-wrapper width-100 ${
          showInvisible ? "expanded" : ""
        }`}
        style={{
          maxHeight: showInvisible ? `400px` : "0px",
          overflowY: "auto",
        }}
      >
        {props.item.name !== "Članovi porodice" ? (
          <p
            className="Grotesk font-16 weight-400 capitalize"
            style={{ color: neutralMedium }}
          >
            {props.item.description}
          </p>
        ) : (
          <FamilyMemberRow familyMembers={props.item.description} />
        )}
      </div>
    </div>
  );
};

export default OtherInfoRow;
