import React from "react";
import { trainingCardArray } from "../constants";
import ProfilePageRow from "./ProfilePageRow";
import { tableHeaderTraining } from "../constants";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";

const TrainingCard = (props) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const backgroundDefault = theme.palette.background.default;
  const neutralLight = theme.palette.neutral.light;
  return (
    <div
      className="flex-direction-column align-end gap-32 shadows-0-0-8 width-100 training-card animated"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-column align-start gap-24 width-100">
        <p className="Grotesk-500 font-16 " style={{ color: neutralMedium }}>
          Karton Obuke
        </p>
        <div className="flex-direction-column justify-center align-start gap-8 width-100 training-table">
          <div className="flex-direction-row align-center gap-24 table-header-training width-100">
            {tableHeaderTraining.map((item, index) => (
              <p
                className={`Grotesk-500 font-16 ${
                  index === 2 ? "hidden-sm" : ""
                } ${index === 1 ? "hidden-xs" : ""}`}
                key={index}
                style={{ color: neutralLight }}
              >
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
      <ButtonApp
        title="Vidi više"
        icon={icons.arrow_right}
        className="team-see-more"
      />
    </div>
  );
};

export default TrainingCard;
