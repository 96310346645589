import React from "react";
import "../styles/Calendar.scss";
import "../../../styles/global.scss";
import DateSwitcher from "./DateSwitcher";

const CalendarNavigation = ({ month, year, changeDate }) => {
  const currentMonth = months.find((localMonth) => localMonth.number == month);
  const currentYear = years.find(
    (localYear) => localYear.number === String(year)
  );

  const handleClick = (type, date) => {
    const firstYear = Number(years[0].number);
    const lastYear = Number(years[years.length - 1].number);
    let newValue;

    if (date === "month") {
      newValue = type === "decrement" ? Number(month) - 1 : Number(month) + 1;
      if (newValue > 12) newValue = 1;
      else if (newValue < 1) newValue = 12;
    } else {
      newValue = type === "decrement" ? Number(year) - 1 : Number(year) + 1;
      if (newValue < firstYear) newValue = firstYear;
      else if (newValue > lastYear) newValue = lastYear;
    }
    changeDate(date, newValue);
  };

  return (
    <div className="flex-direction-row gap-24 calendar-navigation">
      <DateSwitcher
        data={currentMonth}
        onClick={(type) => handleClick(type, "month")}
      />
      <DateSwitcher
        data={currentYear}
        onClick={(type) => handleClick(type, "year")}
      />
    </div>
  );
};

export default CalendarNavigation;

const months = [
  { number: "1", name: "January" },
  { number: "2", name: "February" },
  { number: "3", name: "March" },
  { number: "4", name: "April" },
  { number: "5", name: "May" },
  { number: "6", name: "June" },
  { number: "7", name: "July" },
  { number: "8", name: "August" },
  { number: "9", name: "September" },
  { number: "10", name: "October" },
  { number: "11", name: "November" },
  { number: "12", name: "December" },
];

const years = [
  ...Array.from({ length: new Date().getFullYear() - 1970 + 3 }, (_, index) => {
    const year = 1970 + index;
    return { number: String(year), name: String(year) };
  }),
];
