import React from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import CumulativeViewRow from "./CumulativeViewRow";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../../../../../store/apiSlice"; // Import apiSlice
import Loader from "../../../../../components/Loader/Loader";
import ToastError from "../../../../../components/ToastError/ToastError";
import useInfiniteScroll from "../../../../../hooks/infiniteScroll";

const CumulativeView = ({ from, to, search }) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.company?.data?.companyId);
  const size = 10; // Page size

  // Fetch function (called inside useInfiniteScroll)
  const fetchCumulativeView = async (page) => {
    if (!companyId) return { items: [], totalElements: 0 };

    try {
      const response = await dispatch(
        apiSlice.endpoints.getCumulativeLogs.initiate({
          variables: {
            companyId,
            from,
            to,
            search: search.trim(),
            page,
            size,
          },
        })
      ).unwrap();

      return {
        items: response?.data?.cumulativeLogs?.content || [],
        totalElements: response?.data?.cumulativeLogs?.totalElements || 0,
      };
    } catch (error) {
      console.error("API Error:", error);
      throw new Error("Failed to fetch data");
    }
  };

  // Use infinite scroll with correct API fetching
  const { data, loading, error, observerRef } = useInfiniteScroll(
    fetchCumulativeView,
    [companyId, from, to, search]
  );

  return (
    <div className="flex-direction-column gap-24 width-100 justify-center align-start cumulative-view">
      <div className="flex-direction-row gap-96 width-100 justify-space-between align-start cumulative-view-header">
        <p className="Grotesk-500 font-14 cumulative-view-header-employee">
          Zaposleni
        </p>
        <div className="flex-direction-row flex-1-0-0 gap-4 align-start">
          {header.map((item, index) => (
            <p
              className="Grotesk-500 font-14 flex-1-0-0 cumulative-view-header-item"
              key={index}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="flex-direction-column gap-24 width-100 align-start cumulative-view-content">
        {/* Render cumulative view rows */}
        {data.map((item, index) => (
          <CumulativeViewRow key={index} data={item} />
        ))}
        {/* Loader and error handling */}
        {loading && <Loader />}
        {error && <ToastError message="Failed to load data" />}
        {/* Observer for infinite scroll */}
        <div
          ref={observerRef}
          style={{ minHeight: "40px", width: "100%" }}
        ></div>
      </div>
    </div>
  );
};

export default CumulativeView;

const header = [
  "ID",
  "Upisano vreme",
  "Željeno vreme",
  "Razlika",
  "Plaćeno",
  "Radni dani",
];
