import React, { useState } from "react";
import Calendar from "../Calendar/Calendar";
import "../../styles/global.scss";
import "./styles/DateInput.scss";
import { icons } from "../../constants";
import { useTheme } from "@mui/material";

const DateInput = ({
  onChange,
  doubleDate,
  firstDate,
  secondDate,
  label,
  over,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  const handleDateChange = () => {
    setShowCalendar(false);
  };

  return (
    <div className={`${over ? "over" : ""} date-input`}>
      {label && (
        <label
          className="Grotesk-500 font-16 weight-500 width-100"
          style={{
            color: neutralMedium,
            textAlign: "start",
            lineHeight: "20px",
          }}
        >
          {label}
        </label>
      )}
      <div className="input-wrapper">
        <input
          type="text"
          value={doubleDate ? `${firstDate} - ${secondDate || ""}` : firstDate}
          readOnly
          onClick={() => setShowCalendar(!showCalendar)}
          placeholder="Select a date"
        />
        <button
          className="calendar-icon"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          <img src={icons.calender} alt="kalendar" width={16} height={18} />
        </button>
      </div>
      {showCalendar && (
        <Calendar
          onChange={onChange}
          doubleDate={doubleDate}
          firstDate={firstDate}
          secondDate={secondDate}
          over={over}
        />
      )}
    </div>
  );
};

export default DateInput;
