import React from "react";
import InputApp from "../../InputApp/InputApp";
import CustomFileInput from "../../InputApp/CustomFileInput";

const ModalForm = (props) => {
  return (
    <div className="flex-direction-column gap-20 width-100 modal-form">
      <div className="flex-direction-column gap-12 width-100">
        <p className="Grotesk-500 font-16">
          {props.profileFile
            ? "Choose a folder"
            : props.profileInfo
            ? "Kategorija"
            : "Subject"}
        </p>
        {props.profileFile || props.profileInfo ? (
          <InputApp
            dropdown
            value={props.documentType}
            optionsArray={props.optionsArray}
            width="100%"
            onChange={props.onOptionsChange}
          />
        ) : (
          <InputApp className="modal-input" width="100%" />
        )}
      </div>
      {props.profileFile ? (
        <CustomFileInput
          className="profile-file-input"
          setFile={props.setFile}
        />
      ) : (
        <div className="flex-direction-column gap-12 width-100">
          <p className="Grotesk-500 font-16">
            {props.profileInfo ? "Informacije" : "Objava"}
          </p>
          <InputApp
            value={props.description}
            onChange={props.setDescription}
            textarea
            className="modal-input"
            width="100%"
            height="80px"
          />
        </div>
      )}
    </div>
  );
};

export default ModalForm;
