import React, { useState } from "react";
import "../styles/homepageAdmin.scss";
import "../../../styles/global.scss";
import { useTheme } from "@mui/material";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import { icons, images } from "../../../constants";
import { format, parseISO, differenceInDays } from "date-fns";
import { useGetEmployeesExpirationContractsQuery } from "../../../store/apiSlice";
import { useSelector } from "react-redux";
import { idSelector } from "../../../store/companySlice";

const expiringContracts = [
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "SQ",
    date: "2024-07-01",
  },
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "MP",
    date: "2024-08-01",
  },
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "BA",
    date: "2024-09-01",
  },
  {
    img: images.profilePicture,
    name: "Marinko Zdravković",
    position: "Frontend developer",
    working_unit: "BA",
    date: "2024-07-01",
  },
];

const TableRow = ({ contract }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  const currentDate = new Date();

  let dateClass = "";
  let formattedDate = "";

  if (contract?.contractExpiration) {
    const contractEndDate = parseISO(contract.contractExpiration);
    const daysDifference = differenceInDays(contractEndDate, currentDate);

    if (daysDifference < 0) {
      formattedDate = "Isteklo";
    } else {
      dateClass = getDateClass(daysDifference);
      formattedDate = format(contractEndDate, "dd.MM.yyyy.");
    }
  }

  function getDateClass(daysDifference) {
    if (daysDifference < 30) {
      return "red-text";
    } else if (daysDifference < 60) {
      return "orange-text";
    } else {
      return "green-text";
    }
  }

  const user = contract?.user;
  const photo = contract?.photo || images.profilePicture;

  return (
    <div
      className="flex-direction-row align-center justify-space-between width-100 expiring-contracts-table-row"
      style={{ color: neutralMedium }}
    >
      <div className="flex-direction-row align-center gap-5">
        <img src={photo} alt="image" width={26} height={26} />
        <p className="Grotesk-500 font-14">{`${user?.name} ${user?.surname}`}</p>
      </div>
      <div className="flex-direction-row">
        <p className="Grotesk-400 font-14">{contract?.position?.name}</p>
      </div>
      <div className="flex-direction-row">
        <p className="Grotesk-400 font-14">{contract?.sector?.name}</p>
      </div>
      <div className="flex-direction-row">
        <p className={`Grotesk-400 font-14 ${dateClass}`}>
          {formattedDate || "nedefinisano vreme"}
        </p>
      </div>
    </div>
  );
};

const ExpiringContracts = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const littleLighter = theme.palette.primary.littleLighter;
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLighterMedium = theme.palette.neutral.lighterMedium;

  const companyId = useSelector(idSelector);

  const [searchParams, setSearchParams] = useState({
    search: "",
    page: 0,
    size: 10,
  });

  const { data: { data: { employees = {} } = {} } = {} } =
    useGetEmployeesExpirationContractsQuery({
      companyId,
      ...searchParams,
    });

  const expiringContracts = employees?.content || [];

  return (
    <div
      className="flex-direction-column gap-24 expiring-contracts"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-row align-start align-stretch justify-space-between title-btn">
        <p
          className="Grotesk-500 font-16 expiring-contracts-title"
          style={{ color: neutralLighterMedium }}
        >
          Zaposlenja koja ističu
        </p>
        {/* <ButtonApp
          title="Vidi više"
          icon={icons.arrow_right}
          className="expiring-contracts-see-more"
        /> */}
      </div>
      <div className="flex-direction-column align-start gap-10 align-stretch expiring-contracts-table">
        <div
          className="flex-direction-row align-center table-header width-100"
          style={{ backgroundColor: littleLighter, color: neutralMedium }}
        >
          <p className="Grotesk-400 font-14">Ime</p>
          <p className="Grotesk-400 font-14">Pozicija</p>
          <p className="Grotesk-400 font-14">Radna jedinica</p>
          <p className="Grotesk-400 font-14">Status</p>
        </div>
        <div className="flex-direction-column gap-10 width-100 table-content">
          {expiringContracts?.map((contract) => (
            <React.Fragment key={contract?.id}>
              <TableRow contract={contract} />
              <div className="line"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpiringContracts;
