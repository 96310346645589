import React, { useState, useEffect } from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import EmployeesRow from "../../../../EmployeesPage/components/EmployeesRow";
import WorkHoursTrackerRow from "./WorkHoursTrackerRow";
import { useGetSingleEmployeeLogsQuery } from "../../../../../store/apiSlice";
import Loader from "../../../../../components/Loader/Loader";
import ToastError from "../../../../../components/ToastError/ToastError";

const EmployeePerson = ({ employee, from, to }) => {
  const [data, setData] = useState([]);
  const {
    data: apiData,
    error,
    isLoading,
  } = useGetSingleEmployeeLogsQuery({
    employeeId: employee.id,
    from,
    to,
  });

  useEffect(() => {
    if (apiData) {
      let items = apiData?.data?.singleEmployeeLogs || [];
      items = items.map((item) => ({
        date: item?.logs[0]?.time,
        logs: item?.logs,
      }));
      setData(items);
    }
  }, [apiData]);

  if (isLoading) return <Loader />;

  console.log(data);

  return (
    <div className="flex-direction-column gap-48 width-100 employee-person">
      {error && <ToastError message={error} />}
      <EmployeesRow employee={employee} />
      <div className="flex-direction-column gap-24 justify-center align-start align-stretch">
        {data.map((item, index) => (
          <WorkHoursTrackerRow
            employed={item}
            key={index}
            employeePerson={employee}
          />
        ))}
      </div>
    </div>
  );
};

export default EmployeePerson;
// const employeePerson = {
//   name: "Marko Gvozdenović",
//   email: "markogvozdenovic@gmail.com",
//   id: "2244756469",
//   position: "Frontend developer",
//   jobSector: "Odeljak Beograd",
//   status: "Određeno 6",
// };

// const data = [
//   {
//     date: "1.novembar 2023",
//     daily: "-08:00",
//     current: "+11:00",
//     activities: [
//       { name: "Activity", startTime: 10, endTime: 12, type: "work" },
//       { name: "Activity", startTime: 12, endTime: 13, type: "break" },
//       { name: "Activity", startTime: 13, endTime: 16, type: "work" },
//     ],
//   },
//   {
//     date: "1.novembar 2023",
//     daily: "-08:00",
//     current: "+11:00",
//     activities: [
//       { name: "Activity", startTime: 10, endTime: 12, type: "work" },
//       { name: "Activity", startTime: 12, endTime: 13, type: "break" },
//       { name: "Activity", startTime: 13, endTime: 16, type: "work" },
//     ],
//   },
// ];

// {
// 	"19": {
// 		"id": "2d6055a0-09e8-4c25-9a3e-1e3383cdbcda",
// 		"name": "Djordje",
// 		"photo": "https://dev-bucket-abouthr.fra1.digitaloceanspaces.com/test-company/2d6055a0-09e8-4c25-9a3e-1e3383cdbcda/PHOTO/Symbol%20Gradient%20White.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20250126T121612Z&X-Amz-SignedHeaders=host&X-Amz-Credential=DO004AZP8AZANJXQG2ZV%2F20250126%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Expires=3600&X-Amz-Signature=b208c50d4b5627f3a62f15dd2ff941cf6c47bb6049a6b529e9d52908cc6e365d",
// 		"surname": "Stojanovic",
// 		"logs": [],
// 		"runningTime": 138365,
// 		"todayTime": 0
// 	}
// }
