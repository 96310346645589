import React from "react";

import { useTheme } from "@mui/material";

import ProfilePageRow from "./ProfilePageRow";
import Filter from "../../../components/Filter/Filter";
import { tableHeader } from "../constants";
import Spinner from "../../../components/Spinner/Spinner";

const DocumentsTable = ({
  data,
  onRowClick,
  isLoading,
  title,
  missingDataText,
}) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLight = theme.palette.neutral.light;

  return (
    <div className="flex-direction-column gap-24 align-start width-100">
      <div className="flex-direction-row justify-space-between align-center align-stretch">
        <p
          className="Grotesk-500 font-16 profile-title"
          style={{ color: neutralMedium }}
        >
          {title}
        </p>
        <Filter />
      </div>
      <div className="flex-direction-row align-center gap-24 table-header width-100">
        {tableHeader.map((item, index) => (
          <p
            className={`Grotesk-500 font-16 ${
              index === 1 || index === 2 ? "hidden-sm" : ""
            } ${index === 3 ? "hidden-xs" : ""}`}
            key={index}
            style={{ color: neutralLight }}
          >
            {item}
          </p>
        ))}
      </div>
      <Spinner isLoading={isLoading}>
        <div className="flex-direction-column gap-24 align-start width-100">
          {data && data.length > 0
            ? data.map((item) => (
                <ProfilePageRow
                  item={item}
                  key={item?.id}
                  document
                  onClick={() => onRowClick(item)}
                />
              ))
            : !isLoading && (
                <p
                  className="Grotesk-500 font-16 width-100 text-center"
                  style={{ color: neutralLight }}
                >
                  {missingDataText}
                </p>
              )}
        </div>
      </Spinner>
    </div>
  );
};

export default DocumentsTable;
