import React from "react";
import "./Spinner.scss";

const Spinner = ({ isLoading, children }) => {
  return (
    <div className="spinner-wrapper">
      <div className={`spinner-content ${isLoading ? "loading" : ""}`}>
        {children}
      </div>
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default Spinner;
