import React from "react";
import "../styles/Calendar.scss";
import "../../../styles/global.scss";
import { Image } from "@mui/icons-material";
import { icons } from "../../../constants";

const ArrowButton = ({ type, number, onClick }) => {
  return (
    <div className="width-100 arrow-button">
      <button
        className="flex-direction-row align-center pointer"
        style={{ justifyContent: type === "decrement" ? "start" : "end" }}
        onClick={() => onClick(type)}
      >
        <img
          src={type === "decrement" ? icons.arrow_left : icons.arrow_right}
          height={20}
          width={18}
        />
      </button>
    </div>
  );
};

export default ArrowButton;
