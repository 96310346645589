export const generateCalendarDates = (year, month) => {
  const currentMonth = new Date(year, month - 1); // Mesečni datum (0-indeksiran)
  const firstDayOfWeek = currentMonth.getDay(); // Dan u nedelji za prvi dan (0 = Nedelja, 1 = Ponedeljak, itd.)
  const daysInCurrentMonth = new Date(year, month, 0).getDate(); // Broj dana u mesecu
  const daysInPreviousMonth = new Date(year, month - 1, 0).getDate(); // Broj dana u prethodnom mesecu

  const dates = [];

  // Dodaj prethodne dane iz prošlog meseca (kao sive)
  for (let i = firstDayOfWeek - 1; i >= 1; i--) {
    dates.push({
      day: daysInPreviousMonth - i + 1,
      month: month - 1 === 0 ? 12 : month - 1, // Ako je januar, pređi na decembar
      year: month - 1 === 0 ? year - 1 : year,
      inactive: true, // Obeleži kao sivi dan
    });
  }

  // Dodaj sve dane trenutnog meseca
  for (let i = 1; i <= daysInCurrentMonth; i++) {
    dates.push({
      day: i,
      month: month,
      year: year,
      inactive: false, // Obeleži kao aktivan dan
    });
  }

  // Dodaj dane iz sledećeg meseca da popuni poslednju nedelju
  const remainingDays = 7 - (dates.length % 7);
  if (remainingDays < 7) {
    for (let i = 1; i <= remainingDays; i++) {
      dates.push({
        day: i,
        month: month + 1 > 12 ? 1 : month + 1, // Ako je decembar, pređi na januar
        year: month + 1 > 12 ? year + 1 : year,
        inactive: true, // Obeleži kao sivi dan
      });
    }
  }

  return dates;
};

export const groupDatesByWeeks = (dates) => {
  const weeks = [];
  for (let i = 0; i < dates.length; i += 7) {
    weeks.push(dates.slice(i, i + 7));
  }
  return weeks;
};
