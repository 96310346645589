import React, { useState, useEffect } from "react";
import "./styles/OptionsSearchList.scss";
import "../../styles/global.scss";
import { icons } from "../../constants";

const OptionsSearchList = ({ data, setSearchValue, setChosenRow }) => {
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [skipEffect, setSkipEffect] = useState(false);
  const [openList, setOpenList] = useState(false);

  useEffect(() => {
    if (skipEffect) {
      setSkipEffect(false);
      return;
    }

    const handler = setTimeout(() => {
      setSearchValue(localSearchValue);

      if (!skipEffect) {
        if (localSearchValue !== "" && data && data.length) {
          setOpenList(true);
        } else {
          setOpenList(false);
        }
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [localSearchValue, setSearchValue]);

  const handleChange = (event) => {
    setLocalSearchValue(event.target.value);
  };

  const handleRowClick = (item) => {
    setChosenRow(item);
    setLocalSearchValue(item.name);
    setOpenList(false);
    setSkipEffect(true);
  };

  return (
    <div className="options-search-list-container width-100">
      <div
        className={`flex-direction-row gap-5 flex-1-0-0 align-center options-search-list-input-wrapper ${
          openList && "list-opened"
        }`}
      >
        <img src={icons.search} alt="search icon" className="search-icon" />
        <input
          type="text"
          className="Grotesk-400 font-16 width-100 options-search-input"
          onChange={handleChange}
          value={localSearchValue}
          placeholder="Search..."
        />
      </div>
      {openList && (
        <div className="flex-direction-column gap-6  options-search-list-options-wrapper">
          <div className="flex-direction-column gap-6 align-start align-stretch">
            {data.map((item, index) => (
              <div
                key={item.id}
                className="flex-direction-column gap-6 width-100 pointer"
              >
                <div
                  key={index}
                  className="flex-direction-row gap-8 width-100 align-center options-search-list-option"
                  onClick={() => handleRowClick(item)}
                >
                  {item.photo && (
                    <img
                      src={item.photo}
                      alt="item photo"
                      className="item-photo"
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "100%",
                      }}
                    />
                  )}
                  {Object.entries(item)
                    .filter(([key]) => key !== "id" && key !== "photo")
                    .map(([key, value]) => (
                      <p key={key} className="Grotesk-400 font-16">
                        {value}
                      </p>
                    ))}
                </div>

                {index < data.length - 1 && (
                  <div className="width-100 line"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionsSearchList;
