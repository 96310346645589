import React from "react";
import "./styles/buttonApp.scss";
import { ReactComponent as FolderIcon } from "../../assets/icons/folder.svg";
import { useTheme } from "@mui/material";

const ButtonFolder = ({
  folder,
  baseColor,
  backgroundColor,
  activeColor,
  borderColor,
  active,
  title,
  filesNumber,
  icon,
  number,
  className,
  classNameInside,
  onClickButton,
  disabled,
}) => {
  const theme = useTheme();

  baseColor = theme.palette.primary.darkest;
  if (folder) {
    if (backgroundColor === "lightest") {
      backgroundColor = theme.palette.primary.lightest;
    } else if (backgroundColor === "lighter") {
      backgroundColor = theme.palette.primary.lighter;
    } else if (backgroundColor === "light") {
      backgroundColor = theme.palette.primary.light;
    } else if (backgroundColor === "medium") {
      backgroundColor = theme.palette.primary.medium;
    }
  }

  activeColor = theme.palette.primary.moreDarkest;
  const neutralMedium = theme.palette.neutral.medium;

  const handleClickButton = () => {
    if (!disabled) {
      onClickButton();
    }
  };

  return (
    <div
      className={`flex-direction-column align-center justify-center align-stretch pointer ${
        active ? "active active-border" : ""
      } ${folder ? "button-folder" : "gap-24 button-check-in"} ${
        className ? className : ""
      } ${disabled ? "disabled" : ""}`} // Add 'disabled' class if disabled
      style={{
        color: active ? activeColor : baseColor,
        backgroundColor: backgroundColor ? backgroundColor : "",
        cursor: disabled ? "not-allowed" : "pointer", // Change cursor if disabled
        opacity: disabled ? 0.6 : 1, // Change opacity if disabled
      }}
      onClick={handleClickButton}
    >
      {folder ? (
        <div className="flex-direction-column justify-center align-center gap-4">
          <FolderIcon />
          <p className="Grotesk-500 font-16 capitalize">{title}</p>
        </div>
      ) : (
        <>
          <div
            className={`flex-direction-row justify-center align-center button-check-in-img ${classNameInside}`}
          >
            <img src={icon} width={32} height={32} />
          </div>
          <div className="flex-direction-column justify-center align-center gap-8">
            <p
              className="Grotesk-500 font-24 number"
              style={{ color: neutralMedium }}
            >
              {number}
            </p>
            <p className="Grotesk-500 font-16" style={{ color: neutralMedium }}>
              {title}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ButtonFolder;
