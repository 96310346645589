const getEmployeesQuery = `
    query ($companyId: UUID!, $page: Int! = 0, $size: Int! = 10) {
        employees(companyId: $companyId, page: $page, size: $size) {
            content {
                id
                companyId
                cardId
                address
                user {
                  name
                  surname
                  id
                  email
                  phone
                }
            }
        }
    }
`;

const getAttendanceQuery = `
    query ($companyId: UUID!) {
        attendance(companyId: $companyId) {
            currentAbsence {
                change
                value
            }
            currentAttendance {
                change
                value
            }
            totalEmployees {
                change
                value
            }
            weeklyAttendance {
                change
                value
            }
        }
    }
`;

const employeeHomeQuery = `
    query ($id: UUID!) {
        employee(id: $id) {
            companyId
            dailyLogs {
                id
                location
                time
                type
            }
        }
    }`;

const getCompanyIdByUserIdQuery = `
    query ($id: UUID!) {
        employee(id: $id) {
            companyId
            photo
        }
    }`;
const getCompanyInfoQuery = `
    query ($id: UUID!) {
    company(id: $id) {
        address
        email
        id
        name
        phone
        shortName
        slug
        taxId
    }
    }`;
const getProfilePersonalInfoQuery = `
    query ($id: UUID!) {
      employee(id: $id) {
    cardId
    photo
    user {
      phone
      name
        surname
        email
    }
    address
    position {
      name
      description
    }
    sector {
      description
      name
    }
    birthday
    company {
      address
      shortName
    }
  }
}`;

const getPositionsAndSectorsQuery = `
    query ($id: UUID!) {
        positions (companyId: $id) {
            id
            name
            description
        }
        sectors (companyId: $id) {
            id
            name
            description
        }
    }
`;
const getAttendanceForAdminHomeQuery = `
query ($id: UUID!) {
    attendance(companyId: $id) {
        currentAbsence {
            change
            value
        }
        currentAttendance {
            change
            value
        }
        totalEmployees {
            change
            value
        }
        weeklyAttendance {
            change
            value
        }
    }
}`;
const getListOfEmployeesQuery = `
query ($id: UUID!) {
    employees(companyId: $id, size:100) {
        content {
            user {
                email
                name
                surname
                phone
                id
            }
            photo
            position {
                name
            }
        }
    }
}`;
const getEmploymentInfoQuery = `
    query ($id: UUID!) {
        employment(companyId: $id) {
            fixed
            permanent
            total
        }
    }
`;
const getAttendancePageQuery = `
    query ($companyId: UUID!, $size: Int = 10, $page: Int = 0, $search: String, $date: Date) {
        employeeLogsPerDay(companyId: $companyId, size: $size, page: $page, search: $search, date: $date) {
            content {
                id
                name
                photo
                surname
                logs {
                    id
                    location
                    time
                    type
                }
                runningTime
                todayTime
            }
            totalElements
        }
    }
`;

const getEmployeeDocumentsQuery = `
    query ($employeeId: UUID!,$search: String = "",$page: Int = 0, $size: Int = 20, $type: DocumentType) {
        employeeDocuments(
            employeeId: $employeeId,
            search: $search,
            page: $page,
            size: $size,
            type: $type
        ) {
            page
            size
            totalElements
            totalPages
            content {
                uploaded
                url
                type
                name
                id
                employeeId
                createdAt
                companyId
                size
                downloadedAt
            }
        }
    }
`;

const getCustomFieldsQuery = `
    query ($companyId: UUID!) {
        customFields(companyId: $companyId) {
            companyId
            description
            id
            name
        }
    }
`;

const getEmployeesExpirationContractsQuery = `
  query ($companyId: UUID!, $search: String = "", $size: Int = 10, $page: Int = 0) {
    employees(companyId: $companyId, page: $page, search: $search, size: $size) {
      content {
        contractExpiration
        id
        user {
          name
          surname
        }
        photo
        position {
            name
        }
        sector {
            name
        }
      }
    }
  }
`;

const getSingleEmployeeLogsQuery = `
  query(
    $employeeId: UUID!,
    $from: Date!,
    $to: Date!
  ) {
    singleEmployeeLogs(
      employeeId: $employeeId,
      from: $from,
      to: $to
    ) {
      logs {
        id
        location
        time
        type
      }
    }
  }
`;

const getCumulativeLogsQuery = `
  query(
    $companyId: UUID!,
    $from: String!,
    $to: String!,
    $page: Int!,
    $size: Int!,
    $search: String!
  ) {
    cumulativeLogs(
      companyId: $companyId,
      from: $from,
      to: $to,
      page: $page,
      size: $size,
      search: $search
    ) {
      content {
        paidTime
        loggedTime
        expectedTime
        days
        photo
        surname
        name
      }
    }
  }
`;

const searchEmployeesQuery = `
  query(
    $companyId: UUID!,
    $search: String!
  ) {
    employees(
      companyId: $companyId,
      search: $search
    ) {
      content {
        photo
        sector {
            name
        }
        position {
            name
        }
        user {
          id
          email
          name
          surname
          phone
        }
      }
    }
  }
`;
const cumulativeLogsQuery = `
  query(
    $companyId: UUID!,
    $from: Date!,
    $to: Date!,
    $page: Int!,
    $search: String!,
    $size: Int!
  ) {
    cumulativeLogs(
      companyId: $companyId,
      from: $from,
      to: $to,
      page: $page,
      search: $search,
      size: $size
    ) {
      totalElements
      content {
        days
        expectedTime
        surname
        photo
        name
        paidTime
        loggedTime
      }
    }
  }
`;

export default {
  employeeHomeQuery,
  getCompanyIdByUserIdQuery,
  getEmployeesQuery,
  getAttendanceQuery,
  getCompanyInfoQuery,
  getProfilePersonalInfoQuery,
  getPositionsAndSectorsQuery,
  getAttendanceForAdminHomeQuery,
  getListOfEmployeesQuery,
  getEmploymentInfoQuery,
  getAttendancePageQuery,
  getEmployeeDocumentsQuery,
  getCustomFieldsQuery,
  getEmployeesExpirationContractsQuery,
  getSingleEmployeeLogsQuery,
  getCumulativeLogsQuery,
  searchEmployeesQuery,
  cumulativeLogsQuery,
};
