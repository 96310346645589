import { useState } from "react";
import {
  useConfirmDocumentMutation,
  useCreateDocumentMutation,
} from "../store/apiSlice";
import uploadFile from "./uploadFile";

const useUploadDocument = () => {
  const [createDocument] = useCreateDocumentMutation();
  const [confirmDocument] = useConfirmDocumentMutation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const uploadDocument = async (
    file,
    type,
    companyId,
    employeeId = undefined
  ) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    if (!file) {
      console.warn("No file provided");
      setError("No file provided");
      setLoading(false);
      return;
    }
    if (!type) {
      console.warn("No document type provided");
      setError("No document type provided");
      setLoading(false);
      return;
    }
    if (!companyId) {
      console.warn("No company ID provided");
      setError("No company ID provided");
      setLoading(false);
      return;
    }

    try {
      // Create the document
      const documentResponse = await createDocument({
        input: {
          type: type,
          name: file.name,
          size: file.size,
          companyId: companyId,
          employeeId: employeeId,
        },
      }).unwrap();

      const { id: documentId, url: documentPresignedUrl } =
        documentResponse?.data?.createDocument || {};
      if (!documentId || !documentPresignedUrl) {
        throw new Error("There is no file data");
      }

      // Upload the file
      console.log("Uploading file...");
      await uploadFile(file, documentPresignedUrl);

      // Confirm the document
      const confirmResponse = await confirmDocument({
        id: documentId,
      }).unwrap();
      if (confirmResponse?.errors) {
        throw new Error(confirmResponse.errors[0]);
      }

      setSuccess(true);
      console.log("File uploaded and confirmed successfully!");
    } catch (err) {
      console.error("Upload failed:", err);
      setError(err.message || "An error occurred during upload.");
    } finally {
      setLoading(false);
    }
  };

  return { uploadDocument, loading, error, success };
};

export default useUploadDocument;
