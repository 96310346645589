import React, { useState, useRef } from "react";
import "./styles/InputApp.scss";
import { useTheme } from "@mui/material";
import { Upload } from "@mui/icons-material"; // Example icon from Material-UI
import { icons } from "../../constants";

const CustomFileInput = ({ className, placeholder, icon, setFile }) => {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const theme = useTheme();
  const defaultBackground = theme.palette.background.default;

  return (
    <div
      className={`flex-direction-row align-center justify-center gap-10 custom-file-input ${className} ${
        isFocused ? "focus" : ""
      }`}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0}
      style={{
        backgroundColor: defaultBackground,
      }}
    >
      {icon && <Upload className="upload-icon" />}
      <img src={icons.attach_file} alt="icon" />
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        onBlur={handleBlur}
      />
      <span className="file-name">{fileName || placeholder}</span>
    </div>
  );
};

export default CustomFileInput;
