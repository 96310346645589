import React, { useState, useEffect } from "react";
import "../styles/homepage.scss";
import ReactApexChart from "react-apexcharts";
import "chart.js/auto";
import { useTheme } from "@mui/material";

const ActivityData = [
  // { name: "Activity", startTime: 6, endTime: 12, type: "work" },
  // { name: "Activity", startTime: 12, endTime: 13, type: "break" },
  // { name: "Activity", startTime: 13, endTime: 16, type: "work" },
  // { name: "Activity", startTime: 16, endTime: 18, type: "break" },
];

const sortLogsByTime = (logs) => {
  return [...logs].sort(
    (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
  );
};

const DailyGraph = ({ title, color, list, small, today }) => {
  const sortedLogs = sortLogsByTime(list);

  const ActivityData = [];
  let currentActivity = null;

  for (const log of sortedLogs) {
    const logHours = new Date(log.time).getHours();
    const logMinutes = new Date(log.time).getMinutes();
    const logTime = parseFloat((logHours + logMinutes / 60).toFixed(2));
    // const logTime = new Date(log.time).getHours();

    if (!currentActivity) {
      if (log.type === "CHECK_IN") {
        currentActivity = {
          name: "Activity",
          startTime: logTime,
          endTime: -1,
          type: "work",
        };
      }
    } else {
      // If an activity is ongoing
      if (currentActivity.type === "work" && log.type !== "CHECK_IN") {
        currentActivity.endTime = logTime;
        ActivityData.push(currentActivity);
        currentActivity = {
          name: "Activity",
          startTime: logTime,
          endTime: -1,
          type: "break",
        };
      } else if (currentActivity.type === "break" && log.type === "CHECK_IN") {
        currentActivity.endTime = logTime;
        ActivityData.push(currentActivity);
        currentActivity = {
          name: "Activity",
          startTime: logTime,
          endTime: -1,
          type: "work",
        };
      }
    }

    // Handle edge cases like CHECK_OUT
    if (log.type === "CHECK_OUT" && currentActivity) {
      currentActivity.endTime = logTime;
      ActivityData.push(currentActivity);
      currentActivity = null;
    }
  }
  if (currentActivity && today) {
    ActivityData.push(currentActivity);
  }
  const lastLog = ActivityData[ActivityData.length - 1];
  if (lastLog?.endTime === -1 && today) {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    lastLog.endTime = parseFloat((hours + minutes / 60).toFixed(2));
  }
  const activities = ActivityData;

  const typeColors = {
    work: "#883CEF",
    break: "#5707B1",
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  // Convert activities to ApexCharts series data
  const seriesData = activities.map((activity) => ({
    x: activity.name,
    y: [activity.startTime, activity.endTime],
    fillColor: typeColors[activity.type],
  }));

  // Use theme
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const backgroundAlt = theme.palette.background.alt;

  // ApexCharts Configuration
  const options = {
    series: [
      {
        data: seriesData,
      },
    ],
    chart: {
      type: "rangeBar",
      background: backgroundAlt, // Set chart background color
      events: {
        click: function (event, chartContext, config) {
          return false;
        },
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "75%",
        borderRadius: small ? 4 : 10,
        rangeBarGroupRows: true,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "numeric",
      tickAmount: isSmallScreen ? 12 : 24, // Change tick amount based on screen size
      labels: {
        style: {
          fontFamily: "Outfit",
        },
        formatter: (val) => `${val}`,
      },
      min: 0,
      max: 24,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      row: {
        colors: ["#F2EBF5", backgroundAlt],
        opacity: 1,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="daily-graph-container-wrapper" style={{ flexGrow: 1 }}>
      <div className="daily-graph-container">
        <ReactApexChart
          options={options}
          series={[{ data: seriesData }]}
          type="rangeBar"
          height={small ? 80 : 120}
          className="aca"
        />
      </div>
    </div>
  );
};

export default DailyGraph;
