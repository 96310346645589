import React, { useEffect, useRef, useState } from "react";
import InputApp from "../../../../../components/InputApp/InputApp";
import { icons } from "../../../../../constants";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";
import { useTheme } from "@mui/material";
// import CalendarInput from "../../../../../components/Calendar/Calendar";
import DateInput from "../../../../../components/DateInput/DateInput";
import OptionsSearchList from "../../../../../components/OptionsSearchList/OptionsSearchList";
import { useSearchEmployeesQuery } from "../../../../../store/apiSlice";
import { useSelector } from "react-redux";

const FilterPanel = ({
  setDate,
  date,
  setSearch,
  search,
  setEnterSearch,
  searchList,
  setOption,
  doubleDates,
}) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const debounceRef = useRef(null);
  const [optionSearch, setOptionSearch] = useState("");
  const [options, setOptions] = useState([]);
  const companyId = useSelector((state) => state.company?.data?.companyId);

  const { data } = useSearchEmployeesQuery({
    search: optionSearch,
    companyId: companyId,
  });

  useEffect(() => {
    if (data) {
      let items = data?.data?.employees?.content || [];
      items = items.map((item) => ({
        id: item?.user?.id,
        name: `${item?.user?.name} ${item?.user?.surname}`,
        photo: item?.photo,
        position: item?.position?.name,
        sector: item?.sector?.name,
        email: item?.user?.email,
        phone: item?.user?.phone,
      }));
      setOptions(items);
    }
  }, [data]);

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setEnterSearch(search);
    }, 300);

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [search, setEnterSearch]);

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setOptionSearch(optionSearch);
    }, 300);
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [optionSearch, setOptionSearch]);

  return (
    <div className="flex-direction-row justify-center align-center gap-12 align-stretch filter-panel">
      <div className="flex-direction-row justify-center align-center gap-12 align-stretch">
        {searchList ? (
          <OptionsSearchList
            data={options}
            setSearchValue={setOptionSearch}
            setChosenRow={setOption}
          />
        ) : (
          <InputApp
            className="attendance-page-search"
            placeholder="search"
            firstIcon={icons.search}
            firstIconReplacement={icons.searchPurple}
            onChange={(value) => setSearch(value)}
            value={search}
          />
        )}

        {/* <ButtonApp
          title="Radna jedinica"
          className="workplace-unit"
          textColor={neutralMedium}
          iconFirst={icons.building}
          padding="14px"
        /> */}
      </div>

      <div className="flex-direction-row justify-center align-center gap-12 align-stretch">
        {/* <InputApp
          className="add-action-modal-time"
          onChange={(value) => setDate(value)}
          value={date}
          type="date"
        /> */}
        <DateInput
          onChange={setDate}
          doubleDate={doubleDates ? true : false}
          firstDate={date?.firstDate}
          secondDate={date?.secondDate}
          style={{ maxWidth: "300px" }} //temporary solution
          over={true}
        />
        {/* <ButtonApp
          title="Dodaj"
          purple
          className="filter-panel-add-btn"
          height="52px"
        /> */}
      </div>
    </div>
  );
};

export default FilterPanel;
