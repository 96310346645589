const CalendarDaySelect = ({
  day,
  setSelectedDay,
  selectedDay,
  date,
  doubleDate,
}) => {
  const [yearFirst, monthFirst, onlyDayFirst] = doubleDate
    ? selectedDay.firstDate.split("-")
    : selectedDay.split("-");

  const [yearSecond, monthSecond, onlyDaySecond] =
    selectedDay.secondDate !== undefined
      ? selectedDay.secondDate?.split("-")
      : "";

  let selectedFirst = false;
  let selectedSecond = false;
  let isBetween = false;

  if (
    onlyDayFirst === String(day.day).padStart(2, "0") &&
    monthFirst === String(date.month).padStart(2, "0") &&
    yearFirst === String(date.year)
  ) {
    selectedFirst = true;
  }

  if (
    onlyDaySecond === String(day.day).padStart(2, "0") &&
    monthSecond === String(date.month).padStart(2, "0") &&
    yearSecond === String(date.year)
  ) {
    selectedSecond = true;
  }

  if (doubleDate && selectedDay.firstDate && selectedDay.secondDate) {
    const currentDate = new Date(date.year, date.month - 1, day.day);
    const firstDate = new Date(yearFirst, monthFirst - 1, onlyDayFirst);
    const secondDate = new Date(yearSecond, monthSecond - 1, onlyDaySecond);

    isBetween =
      currentDate > firstDate && currentDate < secondDate ? true : false;
  }

  return (
    <div
      className="flex-direction-row flex-1-0-0 justify-center"
      onClick={() => {
        if (day.inactive) {
          return;
        }
        setSelectedDay(day.day);
      }}
    >
      <div
        className={`flex-direction-row justify-center align-center pointer calendar-day-select ${
          day.inactive ? "inactive" : ""
        } ${!day.inactive && (selectedFirst || selectedSecond) && "selected"} ${
          !day.inactive && isBetween && "between"
        }`}
      >
        <p className="Grotesk-400 font-16 no-select">{day.day}</p>
      </div>
    </div>
  );
};

export default CalendarDaySelect;
