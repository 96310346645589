import React from "react";
import "../styles/Calendar.scss";
import "../../../styles/global.scss";
import DateSelectorHeader from "./DateSelectorHeader";
import {
  generateCalendarDates,
  groupDatesByWeeks,
} from "../../../utils/generateCalendarDates";
import DateSelectorRow from "./DateSelectorRow";

const DateSelector = ({ date, setSelectedDay, selectedDay, doubleDate }) => {
  const fullDates = generateCalendarDates(date.year, date.month);

  const groupedDatesByWeeks = groupDatesByWeeks(fullDates);

  return (
    <div className="flex-direction-column gap-12">
      <DateSelectorHeader />
      <div className="flex-direction-column align-start gap-8 align-stretch">
        {groupedDatesByWeeks.map((week, index) => (
          <DateSelectorRow
            key={index}
            week={week}
            setSelectedDay={(day) => setSelectedDay(day, date.month, date.year)}
            selectedDay={selectedDay}
            doubleDate={doubleDate}
            date={date}
          />
        ))}
      </div>
    </div>
  );
};

export default DateSelector;
