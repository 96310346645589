import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = { data: null, error: null };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    clearUser: (state) => {
      state.data = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const userSelector = (state) => state.user.data;

export const idSelector = createSelector(userSelector, (user) => user?.userId);

export const { setUser, clearUser, setError } = userSlice.actions;
export default userSlice.reducer;
