const filterAndSortDailyLogs = (dailyLogs, list) => {
  const filteredLogs = dailyLogs.filter(
    (log) =>
      log.type === "CHECK_IN" ||
      log.type === "CHECK_OUT" ||
      log.type === "BREAK"
  );
  const types = {
    CHECK_IN: "Dolazak",
    CHECK_OUT: "Odlazak",
    BREAK: "Pauza",
    WORK_FROM_HOME: "Rad od kuce",
    PRIVATE: "Privatan izlazak",
    BUSINESS: "Sluzbeni izlazak",
  };
  dailyLogs.forEach((log) => {
    const time = new Date(log.time);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const displayTime = `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
    const item = {
      _id: log.id,
      type: types[log.type],
      time: displayTime,
      address: log.location,
    };
    list.push(item);
  });

  return filteredLogs;
};

export default filterAndSortDailyLogs;

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 B";

  const units = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = (bytes / Math.pow(1024, i)).toFixed(2);

  return `${size} ${units[i]}`;
}
