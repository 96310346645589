import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = { data: null, error: null };

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.data = action.payload;
    },
    clearCompany: (state) => {
      state.data = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const companySelector = (state) => state.company.data;

export const idSelector = createSelector(
  companySelector,
  (company) => company?.companyId
);

export const { setCompany, clearCompany, setError } = companySlice.actions;
export default companySlice.reducer;
