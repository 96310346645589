import React from "react";
import "../styles/Calendar.scss";
import "../../../styles/global.scss";

const DateSelectorHeader = () => {
  return (
    <div className="flex-direction-row justify-space-between align-stretch">
      {days.map((day, index) => (
        <div
          key={index}
          className="flex-direction-row flex-1-0-0 align-center justify-center date-selector-header"
        >
          <p className="Grotesk-500 font-12 uppercase">{day}</p>
        </div>
      ))}
    </div>
  );
};

export default DateSelectorHeader;

const days = ["PON", "UTO", "SRE", "ČET", "PET", "SUB", "NED"];
